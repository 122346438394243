<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <columns>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="barcode" class="fa-2x has-text-grey"/>
            <p>
              Total # of Equipment
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_equipment | abbreviateCount }}</p>
              <span class="tag is-success">+ 53%</span>
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="helmet-safety" class="fa-2x has-text-grey"/>
            <p>
              Currently Active Work Orders
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.work_orders_in_progress | abbreviateCount }}</p>
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="people-arrows" class="fa-2x has-text-grey"/>
            <p>
              Staff currently carrying out work
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_working_staff | abbreviateCount }}</p>
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="stopwatch" class="fa-2x has-text-grey"/>
            <p>
              Man hours spent
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_man_hours }}</p>
            </div>
          </div>
        </column>
      </columns>

      <columns>
        <column>
          <div class="box is-round p-5">
            <p class="is-size-4 has-text-weight-light mb-3">Faulty Equipment</p>
            <div class="is-flex is-align-items-center is-justify-content-space-between mb-3 mt-2">
              <p class="is-size-1 has-text-weight-bold">
                {{ overview.faulty_equipment }}
              </p>
            </div>
          </div>
          <div class="box is-round p-5">
            <p class="is-size-4 has-text-weight-light mb-3">Checklists</p>
            <div class="is-flex is-align-items-center is-justify-content-space-between mb-3 mt-2">
              <p class="is-size-1 has-text-weight-bold ">
                {{ overview.checklists }}
              </p>
            </div>
          </div>
        </column>
        <column class="is-9" v-if="!$root.isPassiveUser()">
          <div class="box is-fullheight is-round">
            <checklists-line-chart 
              :summary="overview.checklists_graph"
            />
          </div>
        </column>
      </columns>

    </column>
    <column class="is-3">
      <p class="is-size-3">Overall Health</p>
      <p>Operational Equipment vs Faulty Equipment</p>
      <div id="customer-equipment-health">
        <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
      </div>

      <p class="is-size-5 has-text-weight-bold">Recent Activity</p>
      <columns v-for="checklist in overview.recent_checklists" :key="checklist.id">
        <column>
          <p>
            <router-link :to="{
              name: 'checklist-manager',
              params: {
                checklist: checklist.uuid
              }
            }">
              {{ checklist.checklist.name }}
            </router-link>
            <br>
            <small>{{ checklist.inspectable.name }}</small>
            <br>
            <small class="has-text-grey">At {{ checklist.work_order.location.name }}, Updated {{ checklist.updated_at | asCalendar }}</small>
          </p>
        </column>
        <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
          <circular-progress-bar
            tooltip="Current progress for this checklist"
            :total="checklist.answers.length"
            :total-complete="checklist.answers.filter(answer => answer.complete).length"
          />
        </column>
      </columns>
    </column>
  </columns>
</loader>  
</template>
<script>
import { mapGetters } from 'vuex'
import ChecklistsLineChart from './partials/ChecklistsLineChart.vue'
export default {

  components: {
    ChecklistsLineChart
  },

  data: () => ({
    loading: true,
    chartOptions: {
      chart: {
        type: 'donut',
      },
      colors: ['#E91E63', '#1de98a'],
      labels: ['Faulty', 'Operational'],
      legend: {
        show: false
      },
      stroke: {
        colors: ['#212127']
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
        }
      }]
    },
  }),

  async beforeCreate() {
    await this.$store.dispatch('customer/loadOverview', this.$route.params.customer)
    this.loading = false
  },
  
  computed: {
    ...mapGetters('customer', [
      'overview'
    ]),
    series() {
      return [
        this.overview.faulty_equipment,
        this.overview.total_equipment,
      ]
    }
  }
  
}
</script>